















import { Component, Vue } from 'vue-property-decorator'

import CenteredThin from '@/layouts/Panels/CenteredThin30-50.vue'

@Component({
  components: {
    CenteredThin
  }
})
export default class MedicalInstructionsLayout extends Vue {

}
